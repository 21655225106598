import React, {StrictMode, useEffect, useState} from 'react';
import axios from 'axios';
import {authSteps} from "./authSteps";

function Error(props) {

    const [errors, setErrors] = useState([])

    useEffect(
        () => {
            if (props.errors.length > 0) {
                switch (props.errors[0]) {
                    case 'CODE_NOT_CORRECT':
                        // props.setStep(authSteps.STATE_CODE_NOT_VALID);
                        setErrors([{
                            title: 'Введен неверный проверочный код',
                            text: 'К сожалению, введен неверный код. Попробуйте еще раз или запросите код повторно',
                        }])
                        break;
                    case 'TIME_EXPIRED':
                        // props.setStep(authSteps.STATE_EXPIRED);
                        props.errors['title'] = 'Время действия кода истекло';
                        props.errors['text'] = 'Код больше не действителен. Запросите код повторно.';
                        setErrors([{
                            title: 'Время действия кода истекло',
                            text: 'Код больше не действителен. Запросите код повторно.',
                        }])
                        break;
                    case 'PHONE_EXISTS':
                        // props.setStep(authSteps.STATE_PHONE_EXISTS);
                        setErrors([{
                            title: 'Номер телефона занят',
                            text: 'К сожалению, этот номер телефона уже используется',
                        }])
                        break;
                    case 'CAPTCHA_WRONG':
                        // props.setStep(authSteps.STATE_CAPTCHA_WRONG);
                        setErrors([{
                            title: 'Неверная каптча',
                            //TODO temp error message
                            // text: 'Проверка каптчи не пройдена',
                            text: 'Сервис временно не доступен. Попробуйте авторизоваться через Telegram',
                        }])
                        break;
                    default:
                        // props.setStep(authSteps.STATE_ERROR);
                        setErrors([{
                            title: 'Ошибка!',
                            text: props.errors[0].replaceAll("<br>", "\\n"),
                        }])
                }

            } else {
                setErrors([])
            }
        }, [props?.errors])

    if (errors.length === 0) {
        return null;
    }

    return (
        <div className="flex-col">

            <p className="mb-2">
                {errors['title']}
            </p>

            <div className="form-group mb-5">
                <label className=" text-xs font-normal text-red-600">
                    {errors[0]['text']}
                </label>
            </div>
        </div>

    );
}

export default Error;